(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("HightLevel", ["React", "_"], factory);
	else if(typeof exports === 'object')
		exports["HightLevel"] = factory(require("React"), require("_"));
	else
		root["HightLevel"] = factory(root["React"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 